<template>
  <div>
    <v-dialog :model-value="showDialog" max-width="400" persistent>
      <v-card>
        <v-card-text class="pt-4 body-1 text-center">
          <v-row justify-center>
            <v-col cols="12">
              <v-progress-circular
                :size="50"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </v-col>
          </v-row>
          <v-row>
            <v-col>Tietoja haetaan...</v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: "LoadingDialog",
  props: {
    show: Boolean,
  },
  data: function () {
    let me = this;
    return {
      showDialog: me.show,
    };
  },
  watch: {
    show: function (newValue) {
      this.showDialog = newValue;
    },
  },
};
</script>
